@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  list-style: none;
}

.sectionHeight {
  min-height: calc(100vh - 64px);
}

.error {
  padding: 16px;
  border-radius: 6px;
  background: rgb(238, 133, 133);
  color: rgb(87, 0, 0);
  font-style: italic;
  text-align: center;
  border: 1px solid rgb(87, 0, 0);
}

.success {
  padding: 16px;
  border-radius: 6px;
  background: rgb(164, 255, 187);
  color: rgb(0, 87, 19);
  font-style: italic;
  text-align: center;
  border: 1px solid rgb(0, 87, 19);
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  margin: 8px;
  border: 4px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: rgb(250 204 21) transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

small.required {
  color:#F00
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}